const SettingsMenu = [
  {
    heading: 'menu',
    route: '/geo',
    pages: [
      {
        heading: 'Geo Division',
        route: '/geo/geo-division',
        fontIcon: 'fas fa-users',
        svgIcon: 'media/icons/duotune/general/gen025.svg',
      },
      {
        heading: 'Geo District',
        route: '/geo/geo-district',
        fontIcon: 'fas fa-user-circle',
        svgIcon: 'media/icons/duotune/general/gen025.svg',
      },
      {
        heading: 'Geo Upazila',
        route: '/geo/geo-sub-district',
        fontIcon: 'bi-dashboard',
        svgIcon: 'media/icons/duotune/general/gen025.svg',
      },
      // {
      //   heading: "Geo Tree",
      //   route: "/geo/geo-tree",
      //   fontIcon: "fas fa-sitemap",
      //   svgIcon: "media/icons/duotune/general/gen025.svg",
      // },
    ],
  },
];

export default SettingsMenu;
